<template lang="">
    <div>
        <List />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Müşteriler", route: "/customer/" },
      ]);
    },
    components:{
        List : () => import("@/components/customer/List")
    }
}

</script>
<style lang="">
    
</style>